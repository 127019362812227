import axios from 'axios';
import { JsonObject } from '../helpers';
import Environment from '../environment';
import { AuthProvider } from './auth.service';
import { ApiResponse, handleResponse } from '.';

const axiosClient = axios.create({
  baseURL: Environment.SP_API_URL,
  timeout: 10000,
  withCredentials: false,
  responseType: 'json',
  headers: {
    'X-Requested-By': 'surepath',
  },
});

axiosClient.interceptors.request.use((config) => {
  if (AuthProvider.token) {
    config.headers['Surepath-Authorization'] = `Bearer ${AuthProvider.token}`;
  }

  return config;
});

export const post = async (path: string, data: JsonObject): Promise<ApiResponse> => {
  return axiosClient
    .post(path, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(handleResponse);
};

export const get = async (path: string, params?: JsonObject): Promise<ApiResponse> => {
  return axiosClient.get(path, { params }).then(handleResponse);
};
